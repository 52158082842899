// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Header.css */

.header-container {
    position: sticky;
    top: 0;
    background-color: #7c8570;
    padding: 20px;
    text-align: center;
    font-family: 'Georgia', sans-serif;
}

.header-h1 {
    color: lime;
    font-size: 4em; /* Adjust the font size as needed */
    margin: 0; /* Remove default margin to ensure proper alignment */

}
`, "",{"version":3,"sources":["webpack://./src/css/Header.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;IACI,gBAAgB;IAChB,MAAM;IACN,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,kCAAkC;AACtC;;AAEA;IACI,WAAW;IACX,cAAc,EAAE,mCAAmC;IACnD,SAAS,EAAE,qDAAqD;;AAEpE","sourcesContent":["/* Header.css */\n\n.header-container {\n    position: sticky;\n    top: 0;\n    background-color: #7c8570;\n    padding: 20px;\n    text-align: center;\n    font-family: 'Georgia', sans-serif;\n}\n\n.header-h1 {\n    color: lime;\n    font-size: 4em; /* Adjust the font size as needed */\n    margin: 0; /* Remove default margin to ensure proper alignment */\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
