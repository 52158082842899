// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add CSS to create separate rows for logo and links */
.footer-container {
    background-color: #7c8570;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: left;
    padding: 20px;
    font-family: 'Georgia', sans-serif;
    color: lime;
}

/* Style the footer-link-groups row */
.footer-link-groups {
    display: flex;
    flex-direction: row; /* Display links in a row */
    /*align-items: center; !* Center links vertically *!*/
}

/* Optional: Style the links within the footer-link-groups */
.footer-link-group a {
    color: lime; /* Change link color */
    text-decoration: none; /* Remove underlines from links */
    font-size: 16px; /* Adjust font size */
    margin-right: 20px; /* Add spacing between links */
}

/* Style the footer-logo row */
.footer-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    text-align: center;
}

.footer-logo img {
    margin-bottom: 10px;
}
.footer-icons {
    text-align: center;
}



`, "",{"version":3,"sources":["webpack://./src/css/Footer.css"],"names":[],"mappings":"AAAA,uDAAuD;AACvD;IACI,yBAAyB;IACzB,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,aAAa;IACb,kCAAkC;IAClC,WAAW;AACf;;AAEA,qCAAqC;AACrC;IACI,aAAa;IACb,mBAAmB,EAAE,2BAA2B;IAChD,qDAAqD;AACzD;;AAEA,4DAA4D;AAC5D;IACI,WAAW,EAAE,sBAAsB;IACnC,qBAAqB,EAAE,iCAAiC;IACxD,eAAe,EAAE,qBAAqB;IACtC,kBAAkB,EAAE,8BAA8B;AACtD;;AAEA,8BAA8B;AAC9B;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;AACA;IACI,kBAAkB;AACtB","sourcesContent":["/* Add CSS to create separate rows for logo and links */\n.footer-container {\n    background-color: #7c8570;\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    justify-content: center;\n    align-items: center;\n    text-align: left;\n    padding: 20px;\n    font-family: 'Georgia', sans-serif;\n    color: lime;\n}\n\n/* Style the footer-link-groups row */\n.footer-link-groups {\n    display: flex;\n    flex-direction: row; /* Display links in a row */\n    /*align-items: center; !* Center links vertically *!*/\n}\n\n/* Optional: Style the links within the footer-link-groups */\n.footer-link-group a {\n    color: lime; /* Change link color */\n    text-decoration: none; /* Remove underlines from links */\n    font-size: 16px; /* Adjust font size */\n    margin-right: 20px; /* Add spacing between links */\n}\n\n/* Style the footer-logo row */\n.footer-logo {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-bottom: 20px;\n    text-align: center;\n}\n\n.footer-logo img {\n    margin-bottom: 10px;\n}\n.footer-icons {\n    text-align: center;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
