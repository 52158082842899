// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    background-color: #7c8570;
}

.gallery-item img {
    width: 100%;
    height: auto;
    display: block;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .gallery {
        grid-template-columns: 1fr; /* Stack images vertically on smaller screens */
    }
}
`, "",{"version":3,"sources":["webpack://./src/css/Gallery.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qCAAqC;IACrC,SAAS;IACT,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,cAAc;AAClB;;AAEA,2BAA2B;AAC3B;IACI;QACI,0BAA0B,EAAE,+CAA+C;IAC/E;AACJ","sourcesContent":[".gallery {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    gap: 10px;\n    background-color: #7c8570;\n}\n\n.gallery-item img {\n    width: 100%;\n    height: auto;\n    display: block;\n}\n\n/* Responsive adjustments */\n@media (max-width: 600px) {\n    .gallery {\n        grid-template-columns: 1fr; /* Stack images vertically on smaller screens */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
