import React from 'react';
import './css/Header.css'; // You can create a separate CSS file for styling

const Header = () => {
    return (
        <div className="header-container">
            <h1 className="header-h1">Ark Farms</h1>
        </div>
    );
}

export default Header;
