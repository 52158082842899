"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
require("../css/Gallery.css");
var imageList = [
    '/images/gallery/IMG_20231113_004751_977.jpeg',
    '/images/gallery/IMG_20231113_004950_931.jpeg',
    '/images/gallery/IMG_20231114_195242_163.jpeg',
    '/images/gallery/IMG_20231114_195448_676.jpeg',
    '/images/gallery/IMG_20231114_200455_137.jpeg',
    '/images/gallery/IMG_20231114_200646_312.jpeg',
    '/images/gallery/IMG_20231119_181207_831.jpeg',
    '/images/gallery/IMG_20231119_181357_900.jpeg',
    '/images/gallery/IMG_20231119_181453_455.jpeg',
    '/images/gallery/IMG_20231124_195637_006.jpeg',
    '/images/gallery/IMG_20231124_195803_636.jpeg',
    '/images/gallery/IMG_20231124_195909_327.jpeg',
    '/images/gallery/IMG_20231124_195939_122.jpeg',
    '/images/gallery/IMG_20231124_200127_099.jpeg',
    '/images/gallery/IMG_20231124_200201_666.jpeg',
    '/images/gallery/IMG_20231129_223157_656.jpeg',
    '/images/gallery/Resized_Resized_20210920_102447_1710605638360_1710605645740.jpeg',
    '/images/gallery/Resized_Resized_20210925_134055_1710605638749_1710605645938.jpeg',
    '/images/gallery/Resized_Resized_20210925_134106_1710605639053_1710605646135.jpeg',
    '/images/gallery/Resized_Resized_20210925_134115_1710605641630_1710605646212.jpeg',
    '/images/gallery/Resized_Resized_20210925_135024_1710605642100_1710605646483.jpeg',
    '/images/gallery/Resized_Resized_20221016_175201_1710605684822_1710605693500.jpeg',
    '/images/gallery/Resized_Resized_20221016_175524_1710605683442_1710605693311.jpeg',
    '/images/gallery/Resized_Resized_20221016_175532_1710605682801_1710605693203.jpeg',
    '/images/gallery/Resized_Resized_20221016_175607_1710605686408_1710605693873.jpeg',
    '/images/gallery/Resized_Resized_20221016_175706_1710605688234_1710605694176.jpeg',
    '/images/gallery/Resized_Resized_20221016_175837_1710605699137_1710605707387.jpeg',
    '/images/gallery/Resized_Resized_20221016_175850_1710605699416_1710605707537.jpeg',
    '/images/gallery/Resized_Resized_20221021_141416_1710605699796_1710605707721.jpeg',
    '/images/gallery/Resized_Resized_20221021_141427_1710605700077_1710605707937.jpeg',
    '/images/gallery/Resized_Resized_20221021_141442_1710605700406_1710605708257.jpeg',
    '/images/gallery/Resized_Resized_20221110_223607_1710605704967_1710605708621.jpeg',
    '/images/gallery/Resized_Resized_20230916_100035_1710606777363_1710606792298.jpeg',
    '/images/gallery/Resized_Resized_20230916_100100_1710606776334_1710606792079.jpeg',
    '/images/gallery/Resized_Resized_20230927_102057_1710606769742_1710606791827.jpeg',
    '/images/gallery/Resized_Resized_20230927_102106_1710606770082_1710606791874.jpeg',
    '/images/gallery/imagejpeg_1.jpeg',
    '/images/gallery/imagejpeg_2.jpeg',
    '/images/gallery/imagejpeg_3.jpeg',
    '/images/gallery/imagejpeg_4.jpeg',
    '/images/gallery/imagejpeg_5.jpeg',
    '/images/gallery/Resized_20210919_235026_1712175357088.jpg',
    '/images/gallery/Resized_PART_1712175434572_Screenshot_20181006-183655_Gallery_1712175436699.jpg',
    '/images/gallery/Resized_20210915_211552_1712500572743.jpeg',
    '/images/gallery/Resized_PART_1712500654458_Screenshot_20181006-183655_Gallery_1712500656245.jpeg',
    '/images/gallery/Resized_Resized_20210730_214633_1712500578889_1712500656215.jpeg',
    '/images/gallery/Resized_Resized_20210909_221314_1712500563770_1712500655430.jpeg',
    '/images/gallery/Resized_Resized_20210909_222329_1712500564289_1712500655557.jpeg',
    '/images/gallery/Resized_Resized_20210919_003220_1712500569435_1712500655673.jpeg',
    '/images/gallery/Resized_Resized_20221016_175524_1712500627459_1712500656788.jpeg',
    '/images/gallery/Resized_Resized_20221016_175532_1712500635378_1712500657138.jpeg',
    '/images/gallery/Resized_Resized_20221016_175837_1712500634115_1712500656984.jpeg',
    '/images/gallery/Resized_Resized_20221021_141442_1712500639303_1712500657572.jpeg',
    '/images/gallery/Resized_Resized_20221028_181159_1712500650523_1712500658080.jpeg',
    '/images/gallery/Resized_Resized_20221110_223607_1712500642624_1712500657902.jpeg',
];
var Gallery = function () {
    return ((0, jsx_runtime_1.jsx)("div", { className: "gallery", children: imageList.map(function (src, index) { return ((0, jsx_runtime_1.jsx)("div", { className: "gallery-item", children: (0, jsx_runtime_1.jsx)("img", { src: src, alt: "img-".concat(index) }) }, index)); }) }));
};
exports.default = Gallery;
