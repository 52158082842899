import React from 'react';
import Header from './Header';
import Footer from './components/Footer';
import LandingPagePhotos from './components/LandingPagePhotos';

const ArkFarmsLanding = () => {
    return (
        <div className="landing-page-container">
            <Header/>
            <LandingPagePhotos/>
            <Footer/>
        </div>
    );
}

export default ArkFarmsLanding;
