import {BrowserRouter, Route, Routes} from "react-router-dom";
import './css/App.css';
import ArkFarmsLanding from "./ArkFarmsLanding";
import Gallery from "./components/Gallery";

function App() {
  return (
      <BrowserRouter>
              <div>
                <Routes>
                  <Route path="/" element={<ArkFarmsLanding/>}/>
                  <Route path="/gallery" element={<Gallery/>} />
                </Routes>
              </div>
      </BrowserRouter>
  );
}

export default App;
